import "./PrivacyPolicy.css";

export const PrivacyPolicy = ({ handleClick }) => {
  return (
    <div className="privacy">
      <div className="privacy__inner">
        <div className="privacy__inner__cross" onClick={handleClick}>
          <div className="privacy__inner__cross__line"></div>
          <div className="privacy__inner__cross__line"></div>
        </div>
        <div className="privacy__title__container">
          <div className="privacy__title title">Privacy</div>
        </div>
        <div className="privacy__content">
          <h2>Who We Are</h2>
          <p>
            BLACK HORSE IT LIMITED ('we' or 'us' or 'our') gathers and processes your personal information in accordance
            with this privacy notice and in compliance with the relevant data protection regulation and laws. This
            notice provides you with the necessary information regarding your rights and obligations and explains how,
            why, and when we collect and process your personal data.
          </p>
          <p>
            BLACK HORSE IT LIMITED registered at 27, Old Gloucester Street, London, WC1N 3AX, England, Great Britain,
            company number 15015426, and we are a company registered in England.
          </p>
          less Copy code
          <h2>Information That We Collect</h2>
          <p>
            We process your personal information to meet our legal, statutory and contractual obligations, to provide
            you with our products and services, and, where applicable, to administer competitions that we operate on
            behalf of our clients. We will never collect any unnecessary personal data from you and do not process your
            information in any way, other than already specified in this notice.
          </p>
          <p>The personal data that we collect is:</p>
          <ul>
            <li>Name</li>
            <li>Personal Email</li>
            <li>Business Email</li>
            <li>Home Telephone Number</li>
            <li>Work Telephone Number</li>
            <li>Mobile Telephone Number</li>
            <li>Other information you provide</li>
          </ul>
          <p>We collect information in the below ways:</p>
          <ul>
            <li>Business information available on your public blog or social media channel</li>
            <li>Contact information provided by you via social media communication</li>
            <li>Email communication</li>
          </ul>
          <h2>How We Use Your Personal Data</h2>
          <p>
            We take your privacy very seriously and will never disclose, share, or sell your data without your consent
            unless required to do so by law. We only retain your data for as long as is necessary and for the purposes
            specified in this notice. Where you have consented to us providing you with promotional offers and
            marketing, you are free to withdraw consent at any time. The purposes and reasons for processing your
            personal data are detailed below:
          </p>
          <ol>
            <li>
              We collect and store your personal data to contact you in connection with the services in which you have
              expressed an interest on this site.
            </li>
            <li>We share your personal data with our client where applicable to the above activity.</li>
          </ol>
          <h2>Your Rights</h2>
          <p>
            You have the right to access any personal information that we process about you and to request information
            about:
          </p>
          <ul>
            <li>What personal data do we hold about you</li>
            <li>The purposes of the processing</li>
            <li>The categories of personal data concerned</li>
            <li>The recipients to whom the personal data has/will be disclosed</li>
            <li>How long do we intend to store your personal data for</li>
            <li>If we did not collect the data directly from you, information about the source</li>
          </ul>
          <p>
            If you believe that we hold any incomplete or inaccurate data about you, you have the right to ask us to
            correct and/or complete the information, and we will strive to update/correct it as quickly as possible;
            unless there is a valid reason for not doing so, at which point you will be notified.
          </p>
          <p>
            You also have the right to request the erasure of your personal data or to restrict processing in accordance
            with data protection laws, as well as to object to any direct marketing from us and to be informed about any
            automated decision-making that we use.
          </p>
          <p>
            If we receive a request from you to exercise any of the above rights, we may ask you to verify your identity
            before acting on the relevant request; this is to ensure that your data is protected and kept secure.
          </p>
          <h2>Sharing and Disclosing Your Personal Information</h2>
          <p>
            We do not share or disclose any of your personal information without your consent, other than for the
            purposes specified in this notice or where there is a legal requirement. We provide services and business
            functions to third parties who will only process your data in accordance with instructions from us and
            comply fully with this privacy notice, the data protection laws, and any other appropriate confidentiality
            and security measures.
          </p>
          <h2>Safeguarding Measures</h2>
          <p>
            We take your privacy seriously, and we take every reasonable measure and precaution to protect and secure
            your personal data. We work hard to protect you and your information from unauthorized access, alteration,
            disclosure, or destruction and have several layers of security measures in place, including SSL,
            safeguarding practices, and authentication.
          </p>
          <h2>Consequences of Not Providing Your Data</h2>
          <p>
            You are not obligated to provide your personal information to us. However, as this information is required
            for us to contact you for the purposes outlined above, we will not be able to offer some/all of our products
            or services without it, and where applicable, we will not be able to accept and consider your entry into the
            competition.
          </p>
          <h2>How Long We Keep Your Data</h2>
          <p>
            We only ever retain personal information for as long as is necessary, and we have strict review and
            retention policies in place to meet these obligations. We are required under UK tax law to keep your basic
            personal data (name, address, contact details) for a minimum of 6 years after which time it will be
            destroyed.
          </p>
          <p>
            Where you have consented to us using your details for direct marketing, we will keep such data until you
            notify us otherwise and/or withdraw your consent.
          </p>
          <h2>Lodging A Complaint</h2>
          <p>
            We only process your personal information in compliance with this privacy notice and in accordance with the
            relevant data protection laws. If, however, you wish to raise a complaint regarding the processing of your
            personal data or are unsatisfied with how we have handled your information, you have the right to lodge a
            complaint with the supervisory authority.
          </p>
          <h2>Contact Information</h2>
          <p>Digital Marketing London Agency</p>
          <p>
            Message us using the form <a href="contact-form.html">here</a>.
          </p>
          <h2>Terms &amp; Conditions</h2>
          <p>
            ATTENTION: This legal notice applies to the entire contents of this website https://blackhorseit.co
            (Website) and to any correspondence by e-mail between us and you. Please read these terms carefully before
            using this Website. Using this Website indicates that you accept these terms regardless of whether or not
            you choose to register with us. If you do not accept these terms, do not use this Website. This notice is
            issued by BLACK HORSE IT LIMITED (Company).
          </p>
          <h3>1. INTRODUCTION</h3>
          <p>1.1 You may access most areas of this Website without registering your details with us.</p>
          <p>
            1.2 By accessing any part of this Website, you shall be deemed to have accepted this legal notice in full.
            If you do not accept this legal notice in full, you must leave this Website immediately.
          </p>
          <p>
            1.3 The Company may revise this legal notice at any time by updating this posting. You should check this
            Website from time to time to review the then current legal notice because it is binding on you. Certain
            provisions of this legal notice may be superseded by expressly designated legal notices or terms located on
            particular pages at this Website.
          </p>
          <h3>2. LICENCE</h3>
          <p>
            2.1 You are permitted to print and download extracts from this Website for your own use on the following
            basis:
          </p>
          <ol>
            <li>no documents or related graphics on this Website are modified in any way;</li>
            <li>no graphics on this Website are used separately from the accompanying text; and</li>
            <li>the Company's copyright and trademark notices and this permission notice appear in all copies.</li>
          </ol>
          <p>
            2.2 Unless otherwise stated, the copyright and other intellectual property rights in all material on this
            Website (including without limitation photographs and graphical images) are owned by the Company or its
            licensors. For the purposes of this legal notice, any use of extracts from this Website other than in
            accordance with paragraph 2.1 above for any purpose is prohibited. If you breach any of the terms in this
            legal notice, your permission to use this Website automatically terminates, and you must immediately destroy
            any downloaded or printed extracts from this Website.
          </p>
          <p>
            2.3 Subject to paragraph 2.1, no part of this Website may be reproduced or stored in any other website or
            included in any public or private electronic retrieval system or service without the Company's prior written
            permission.
          </p>
          <p>2.4 Any rights not expressly granted in these terms are reserved.</p>
          <h3>3. SERVICE ACCESS</h3>
          <p>
            3.1 While the Company endeavors to ensure that this Website is normally available 24 hours a day, the
            Company shall not be liable if for any reason this Website is unavailable at any time or for any period.
          </p>
          <p>
            3.2 Access to this Website may be suspended temporarily and without notice in the case of system failure,
            maintenance, or repair or for reasons beyond the Company's control.
          </p>
          <h3>4. VISITOR MATERIAL AND CONDUCT</h3>
          <p>
            4.1 Other than personally identifiable information, which is covered under our Privacy Policy, any material
            you transmit or post to this Website shall be considered non-confidential and non-proprietary. The Company
            shall have no obligations with respect to such material. The Company and its designees shall be free to
            copy, disclose, distribute, incorporate, and otherwise use such material and all data, images, sounds, text,
            and other things embodied therein for any and all commercial or non-commercial purposes.
          </p>
          <p>4.2 You are prohibited from posting or transmitting to or from this Website any material:</p>
          <ul>
            <li>
              that is threatening, defamatory, obscene, indecent, seditious, offensive, pornographic, abusive, liable to
              incite racial hatred, discriminatory, menacing, scandalous, inflammatory, blasphemous, in breach of
              confidence, in breach of privacy or which may cause annoyance or inconvenience; or
            </li>
            <li>for which you have not obtained all necessary licenses and/or approvals; or</li>
            <li>
              which constitutes or encourages conduct that would be considered a criminal offense, give rise to civil
              liability, or otherwise be contrary to the law of or infringe the rights of any third party, in the UK or
              any other country in the world; or
            </li>
            <li>
              which is technically harmful (including, without limitation, computer viruses, logic bombs, Trojan horses,
              worms, harmful components, corrupted data, or other malicious software or harmful data).
            </li>
          </ul>
          <p>4.3 You may not misuse the Website (including, without limitation, by hacking).</p>
          <p>
            4.4 The Company shall fully cooperate with any law enforcement authorities or court order requesting or
            directing the Company to disclose the identity or locate anyone posting any material in breach of paragraph
            4.2 or paragraph 4.3.
          </p>
          <h3>5. LINKS TO AND FROM OTHER WEBSITES</h3>
          <p>
            5.1 Links to third-party websites on this Website are provided solely for your convenience. If you use these
            links, you leave this Website. The Company has not reviewed all of these third-party websites and does not
            control and is not responsible for these websites or their content or availability. The Company, therefore,
            does not endorse or make any representations about them, any material found there, or any results that may
            be obtained from using them. If you decide to access any of the third-party websites linked to this Website,
            you do so entirely at your own risk.
          </p>
          <p>
            5.2 If you would like to link to this Website, you may only do so on the basis that you link to, but do not
            replicate, the home page of this Website, and subject to the following conditions:
          </p>
          <ol>
            <li>you do not remove, distort or otherwise alter the size or appearance of the website;</li>
            <li>you do not create a frame or any other browser or border environment around this Website;</li>
            <li>
              you do not in any way imply that the Company is endorsing any products or services other than its own;
            </li>
            <li>
              you do not misrepresent your relationship with the Company nor present any other false information about
              the Company;
            </li>
            <li>
              you do not otherwise use any trademarks displayed on this Website without express written permission from
              the Company;
            </li>
            <li>you do not link from a website that is not owned by you; and</li>
            <li>
              your website does not contain content that is distasteful, offensive, or controversial, infringes any
              intellectual property rights or other rights of any other person or otherwise does not comply with all
              applicable laws and regulations.
            </li>
          </ol>
          <p>
            5.3 The Company expressly reserves the right to revoke the right granted in paragraph 5.2 for a breach of
            these terms and to take any action it deems appropriate.
          </p>
          <p>
            5.4 You shall fully indemnify the Company for any loss or damage suffered by the Company or any of its group
            companies for breach of paragraph 5.2.
          </p>
          <h3>6. DISCLAIMER</h3>
          <p>
            6.1 While the Company endeavors to ensure that the information on this Website is correct, the Company does
            not warrant the accuracy and completeness of the material on this Website. The Company may make changes to
            the material on this Website, or to the products and prices described in it, at any time without notice. The
            material on this Website may be out of date, and the Company makes no commitment to update such material.
          </p>
          <p>
            6.2 The material on this Website is provided "as is," without any conditions, warranties, or other terms of
            any kind. Accordingly, to the maximum extent permitted by law, the Company provides you with this Website on
            the basis that the Company excludes all representations, warranties, conditions, and other terms (including,
            without limitation, the conditions implied by law of satisfactory quality, fitness for purpose, and the use
            of reasonable care and skill) which but for this legal notice might have effect in relation to this Website.
          </p>
          <h3>7. LIABILITY</h3>
          <p>
            7.1 The Company, any other party (whether or not involved in creating, producing, maintaining, or delivering
            this Website), and any of the Company's group companies and the officers, directors, employees,
            shareholders, or agents of any of them, exclude all liability and responsibility for any amount or kind of
            loss or damage that may result to you or a third party (including without limitation, any direct, indirect,
            punitive or consequential loss or damages, or any loss of income, profits, goodwill, data, contracts, use of
            money, or loss or damages arising from or connected in any way to business interruption, and whether in tort
            (including without limitation negligence), contract, or otherwise) in connection with this Website in any
            way or in connection with the use, inability to use or the results of use of this Website, any websites
            linked to this Website, or the material on such websites, including but not limited to loss or damage due to
            viruses that may infect your computer equipment, software, data or other property on account of your access
            to, use of, or browsing this Website or your downloading of any material from this Website or any websites
            linked to this Website.
          </p>
          <p>
            7.2 While we take all reasonable attempts to exclude viruses from this website, we cannot ensure such
            attempts will be successful and we do not accept any liability for such viruses. You are recommended to take
            all appropriate safeguards before downloading information from this website.
          </p>
          <p>7.3 Nothing in this legal notice shall exclude or limit the Company's liability for:</p>
          <ol>
            <li>
              death or personal injury caused by negligence (as such term is defined by the Unfair Contract Terms Act
              1977); or
            </li>
            <li>fraud; or</li>
            <li>misrepresentation as to a fundamental matter; or</li>
            <li>any liability which cannot be excluded or limited under applicable law.</li>
          </ol>
          <p>
            7.4 If your use of material on this Website results in the need for servicing, repair, or correction of
            equipment, software, or data, you assume all costs thereof.
          </p>
          <p>
            7.5 You agree to defend, indemnify and hold us harmless from and against any and all claims, damages, costs,
            and expenses arising from or related to your breach of any of these terms.
          </p>
          <h3>8. GOVERNING LAW AND JURISDICTION</h3>
          <p>
            This legal notice shall be governed by and construed in accordance with English law. Disputes arising in
            connection with this legal notice shall be subject to the exclusive jurisdiction of the English courts.
          </p>
        </div>
      </div>
    </div>
  );
};
